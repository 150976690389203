<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-flash" v-if="!isMobile">
      <v-card color="#f6f9fe" elevation="0" height="490">
        <v-virtual-scroll
          :items="listVideos"
          :item-height="260"
          height="490"
        >
          <template v-slot:default="{ item }">
            <div class="pr-0 pl-0 v-list-item">
              <div class="pt-0 pl-0 pr-0 col col-12">
                <div class="demo-wrap">
                  <v-img 
                    :id="'img-v' + item.id"
                    class="rounded-video-img demo-bg"
                    height="240"
                    :src="item.thumbnail || ''"
                    :lazy-src="item.thumbnail || ''"
                    gradient="0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)"
                    style="cursor: pointer; border-radius: 10px;"
                    @click="openM(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </v-row>
                    </template>
                    <img src="../../assets/icon/play-circle.svg" color="#fff" class="mt-12 flash__img-play" style="font-size: 100px;">
                    <div class="layout justify-center">
                      <span class="text-1-1-lines text-title px-5" style="color: #fff !important;">
                        {{ item.title }}
                      </span>
                    </div>
                  </v-img>
                </div>
              </div>
            </div>
          </template>
        </v-virtual-scroll>
      </v-card>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-flash" v-else>
      <hooper
        class=""
        ref="carousel"
        :infiniteScroll="true"
        :vertical="false"
        style="height: 100px"
        :itemsToShow="itemsToShow"
        :centerMode="true"
        :playSpeed="4000"
      >
        <slide v-for="data in listVideos" :key="data.id">
          <v-img
            loading="lazy"
            style="cursor: pointer"
            :key="data.id"
            :id="'img-v' + data.id"
            @click="openM(data)"
            :src="data.thumbnail"
            class="rounded-video"
          >
            <v-icon
              style="font-size: 50px"
              class="ml-1 mt-6"
              color="#fff"
              x-large
            >
              mdi-play
            </v-icon>
          </v-img>
        </slide>
      </hooper>
    </div>

    <v-overlay opacity="0.8" color="#000" :value="modal.video" persistent>
      <br />
      <v-layout justify-center>
        <v-btn class="mb-2" dark text small @click="modal.video = false">
          <strong> {{ $translate("flash.buttonClose") }} </strong>
        </v-btn>
      </v-layout>

      <video
        autoplay
        style="background-color: #000"
        :ref="'video' + infoVideo.id"
        :id="'video' + infoVideo.id"
        :src="infoVideo.url"
      />
    </v-overlay>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>

    <v-dialog max-width="800" v-model="open">
      <v-card elevation="0" style="background-color: #f0f8ff00">
        <v-layout justify-center>
          <v-btn color="#466be3" class="mb-2" dark small @click="open = false">
            <strong> {{ $translate("flash.buttonClose") }} </strong>
          </v-btn>
        </v-layout>
        <div class="video-container">
          <video
            ref="videoPlayer1"
            class="video-js vjs-default-skin"
            controls
            preload="auto"
            width="100%"
            height="400"
          >
            <source :src="playerOptions.sources[0].src" type="video/mp4">
          </video>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

import { Flicking } from "@egjs/vue-flicking";
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import Hls from "hls.js";

import $ from "jquery";
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

export default {
  mixins: [alert],
  components: {
    Flicking: Flicking,
    Hls,
    Hooper,
    Slide,
    HooperPagination,
    isMobile: false
  },
  data() {
    return {
      playerOptions: {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
          type: "application/x-mpegURL",
          src: ""
        }]
      },
      player: null,
      open: false,
      showA: false,
      itemsToShow: 4.3,
      value: 0,
      query: false,
      show: true,
      interval: 0,
      listVideos: [],
      output: "",
      loadingAll: false,
      userInfo:{},
      token: "",
      infoToken: "",
      modal: {
        video: false,
      },
      infoVideo: {
        id: null,
        url: null,
      },
      nextVideo: null,
      currentUser:[],
    };
  },
  watch: {
    open: function (e) {
      if (!e) {
        this.playerOptions.muted = true;
      }
    },
    user(newUser) {
      if (newUser) {
        this.currentUser = newUser;
        this.getVideos();
      }
    },
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        //this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        var docList = this.infoToken.document;
        //console.log(this.infoToken);

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    // FLASH NUEVO
    openM(item) {
      this.open = true;
      this.playerOptions.sources[0].src = item.url;
      this.$nextTick(() => {
        if (this.player) {
          this.player.dispose();
        }
        const videoElement = this.$refs.videoPlayer1;
        if (videoElement) {
          this.player = videojs(videoElement, this.playerOptions);
          this.player.ready(() => {
            this.player.play();
          });
        }
      });
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    // FLASH ANTES
    reviewVideo(data) {
      console.log("Actual: " + data.id);
      console.log("Anterior: " + this.nextVideo);

      var key = "videoAuto" + this.nextVideo; // anterior
      var keyIcon = "icon-video" + this.nextVideo; // anterior
      var keyIconNew = "icon-video" + data.id;
      if (this.nextVideo != null) {
        if (this.nextVideo != data.id) {
          console.log("Click elemento");
          console.log(this.$refs[key]);
          this.$refs[key].pause();
        }
      }
      this.nextVideo = data.id;
    },
    getFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    queryAndIndeterminate() {
      // this.query = true
      this.show = true;
      this.value = 0;
      this.interval = setInterval(() => {
        if (this.value === 100) {
          clearInterval(this.interval);
          this.show = false;
          return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.value += 25;
      }, 1000);
    },
    openVideo(data) {
      // console.log("*****");
      // console.log(data);
      // console.log(i);

      // this.showA = true;
      // console.log(this.listVideos);
      // setTimeout(() => {
      //   this.listVideos[i].show = true;
      // }, 100);
      // this.$forceUpdate();
      this.modal.video = true;
      this.infoVideo = data;
      // console.log(data);
      var videoId = document.getElementById("video" + data.id);
      setTimeout(() => {
        var tiempo = $("#video5257")[0].duration;
        this.queryAndIndeterminate();
        // console.log(tiempo);
      }, 1000);
    },
    getVideos() {
      const token = localStorage.getItem("tokenB64");
      if (!token) {
        console.error('Token no definido');
        return;
      }

      this.loadingAll = true;
      const requestBody = JSON.stringify({
        limit: 10,
        vigencia: 0,
      });

      Api.noAuth()
        .showVideos(this.currentUser.document_number, this.currentUser.profile.id, {
          pais: this.currentUser.country || '',
          ciudad: this.currentUser.headquarters_city || '',
          sede: this.currentUser.headquarters || '',
        })
        .then((resp) => resp.json())
        .then((data) => {
          if (data?.cod === 0 && data?.data) {
            this.listVideos = data.data.map(item => ({
              id: item.post_id,
              title: item.post_title,
              thumbnail: item.outstanding_image,
              url: item.video
            }));
          }
        })
        .catch((error) => {
          console.error("Error al obtener videos:", error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    async print(id, pos) {
      const el = this.$refs["printMe-" + id];
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
      };
      this.listVideos[pos].newImg = await this.$html2canvas(el, options);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  created() {
    this.getToken();
    if (screen.width > 425) {
      this.itemsToShow = 8.3;
    }
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.currentUser = this.$store.getters.getUser;
    if (this.currentUser) {
      this.getVideos();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style>
.triangulo {
  width: 0;
  height: 0;
  border-left: 100px solid #f0ad4e;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
}
.flash__demo-content {
  width: 100% !important;
  z-index: 2;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}
/* .demo-wrap {
  position: relative; 
} */

.demo-bg {
  /* opacity: 0.6; */
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

/* .demo-content {
  position: relative;
} */

.video-js {
  width: auto !important;
  height: 550px;
}

.weather-panel {
  width: 200px;
  height: 250px;
  background-color: violet;
  border: 1px solid;
}

.video {
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}

.player {
  position: initial !important;
}

.video-js {
  background-color: black !important;
}

.mb-sm-flash {
  display: none;
}

.flash__img-play {
  cursor: pointer;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    border-radius: 10px;
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }
  .video-js {
    width: auto !important;
    height: 430px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }
  .video-js {
    width: auto !important;
    height: 350px;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
  }
  .video-js {
    width: auto !important;
    height: 300px;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-flash {
    display: none;
  }
  .mb-sm-flash {
    display: block;
    text-align: initial;
  }
  .rounded-video {
    width: 60px;
    height: 96px;
    filter: drop-shadow(0px 0px 1px rgba(62, 123, 250, 0.2))
      drop-shadow(0px 2px 4px rgba(70, 107, 227, 0.4));
    border: 1px solid #4c6bf8;
  }
  .v-dialog {
    margin: 0px !important;
  }

  .video-js {
    width: auto !important;
    height: 300px;
  }
}

.video-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
}

.video-js {
  width: 100% !important;
  height: 400px !important;
}

.video-js .vjs-tech {
  width: 100% !important;
  height: 100% !important;
}
</style>
